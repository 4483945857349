<template>
  <ib-dialog
    :visible="visible"
    :fullscreen="true"
    :show-close="false"
    empty-header
  >
    <ib-dialog-container
      class="product-tour-container"
      max-width="820px"
    >
      <!-- Logo -->
      <!--      <div class="d-flex">-->
      <!--        <img-->
      <!--          src="@/assets/img/logos/ideaBuddy-color.svg"-->
      <!--          width="150"-->
      <!--          alt="logo"-->
      <!--        >-->
      <!--      </div>-->
      <!-- /Logo -->

      <!-- Product Tour Header -->
      <el-row class="product-tour-header">
        <el-col class="text-center el-col-sm-offset-0 mb-2">
          <h1 class="product-tour-title font-weight-semi-bold mt-0 mb-2">
            {{ $t('dialogs.productTour.pickAPlace') }}
          </h1>
          <p class="mt-0 o-50">
            {{ $t('dialogs.productTour.chooseHowYouWantToWork') }}
          </p>
        </el-col>
      </el-row>
      <!-- /Product Tour Header -->

      <!-- Product tour cards -->
      <!--      <product-tour-cards />-->
      <div class="cards-wrapper">
        <product-tour-card
          :title="$t('dialogs.productTour.cards.businessGuide.title')"
          :description="$t('dialogs.productTour.cards.businessGuide.description')"
          :img="require(`@/assets/img/icons/product-tour/businessGuide.svg`)"
          :active="routeName === 'business-guide'"
          route-name="business-guide"
          @click="routeName = $event"
        />
        <product-tour-card
          :title="$t('dialogs.productTour.cards.financialPlan.title')"
          :description="$t('dialogs.productTour.cards.financialPlan.description')"
          :img="require(`@/assets/img/icons/product-tour/financialPlan.svg`)"
          :active="routeName === 'financial-plan'"
          route-name="financial-plan"
          @click="routeName = $event"
        />
        <product-tour-card
          :title="$t('dialogs.productTour.cards.businessPlan.title')"
          :description="$t('dialogs.productTour.cards.businessPlan.description')"
          :img="require(`@/assets/img/icons/product-tour/businessPlan.svg`)"
          :active="routeName === 'business-plan'"
          :show-upgrade="showUpgrade"
          route-name="business-plan"
          @click="routeName = $event"
        />
      </div>
      <div class="cards-wrapper">
        <product-tour-card
          :title="$t('dialogs.productTour.cards.whiteboard.title')"
          :description="$t('dialogs.productTour.cards.whiteboard.description')"
          :img="require(`@/assets/img/icons/product-tour/whiteboard.svg`)"
          :active="routeName === 'whiteboard'"
          route-name="whiteboard"
          @click="routeName = $event"
        />
        <product-tour-card
          :title="$t('dialogs.productTour.cards.ideaPlan.title')"
          :description="$t('dialogs.productTour.cards.ideaPlan.description')"
          :img="require(`@/assets/img/icons/product-tour/ideaPlan.svg`)"
          :active="routeName === 'idea-plan'"
          route-name="idea-plan"
          @click="routeName = $event"
        />
        <product-tour-card
          :title="$t('dialogs.productTour.cards.validation.title')"
          :description="$t('dialogs.productTour.cards.validation.description')"
          :img="require(`@/assets/img/icons/product-tour/validation.svg`)"
          :active="routeName === 'validation-idea-score'"
          :show-upgrade="showUpgrade"
          route-name="validation-idea-score"
          @click="routeName = $event"
        />
      </div>
      <!-- /Product tour cards -->
      <div class="d-flex flex-column flex-md-row align-items-center justify-content-end">
        <ib-button
          class="mt-2"
          :disabled="!routeName"
          @click="onClick"
        >
          CONTINUE
        </ib-button>
      </div>

    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ProductTourCard from '@/views/SelectIdea/Components/ProductTourCard.vue'
// import ProductTourCards from '@/views/SelectIdea/Components/ProductTourCards'

export default {
  name: 'DialogProductTour',

  components: {
    ProductTourCard
    // ProductTourCards
  },

  mixins: [MixinUserSubscription],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      routeName: null
    }
  },

  computed: {
    showUpgrade () {
      return this.subscriptionIsDreamer
    },

    intercomData () {
      return {
        'business-guide': 'Guide',
        'financial-plan': 'Financials (Financial Plan)',
        'business-plan': 'Business Plan',
        whiteboard: 'Whiteboard',
        'idea-plan': 'Canvas (Idea Plan)',
        'validation-idea-score': 'Validation'
      }
    },

    selectedIntercomParam () {
      if (!this.routeName) return null

      return this.intercomData[this.routeName]
    }
  },

  methods: {
    onClick () {
      this.$intercom.update({
        ProductTour: this.selectedIntercomParam
      })
      this.$router.push({ name: this.routeName })
    }
  }
}
</script>

<style scoped lang="scss">

.product-tour-container {
   padding: 0 40px;

  .product-tour-header {
    margin: 20px 0 30px;

    p {
      font-size: 18px;
    }

    .product-tour-title {
      font-size: 31px;
      font-weight: 500;
    }
  }

  .cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up($md) {
      flex-direction: row;
      column-gap: 16px;
    }
  }
}

</style>
